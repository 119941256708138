@tailwind base;
@tailwind components;
@tailwind utilities;

#container,
#go-back-wrapper {
  width: calc(100% - 24px);
  max-width: 800px;
  margin: 0 auto;
}


#container:not(.loading) #loader {
    display: none;
}

#container.loading:not(.preview) #charts {
    display: none;
}

#container:not(.preview) #preview-separator {
  display: none;
}

.stat {
    @apply
        flex
        flex-col
        mb-4
        w-1/2
        md:w-1/4;
}

.stat > label {
    @apply text-xs font-bold text-gray-700;
}

.stat > span {
    @apply text-lg font-light;
}

/* Loader from loading.io */
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    @apply bg-gray-700;

    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  